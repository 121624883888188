import React from 'react';
import CssAnimation from './CssAnimation';
import style from "../style.module.css";
import base from "../../assets/animation/dog/base.png";
import ear1 from "../../assets/animation/dog/ear1.png";
import ear2 from "../../assets/animation/dog/ear2.png";
import face from "../../assets/animation/dog/face.png";

const Dog = () => {
    return (
        <CssAnimation className={style.puppy}>
            <img src={base} alt="Dog" />
            <div className={`${style.puppyHead} ${style.animatedLayer}`}>
                <img src={ear1} alt="Ear" className={`${style.puppyEarRight} ${style.animatedLayer}`} />
                <img src={face} alt="Face" className={`${style.puppyFace}`} />
                <img src={ear2} alt="Ear" className={`${style.puppyEarLeft} ${style.animatedLayer}`} />
            </div>
        </CssAnimation>
    )
}

export default Dog;