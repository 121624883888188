import React, { useRef, useEffect } from 'react';
import { useLottie } from "lottie-react";
import IframeResizer from 'iframe-resizer-react';

import { heartJson, entryJson, exitJson } from "../../assets/index";
import Animation from '../../components/Animation';
import style from "../style.module.css";

const Testimonials = ({ button = true, hover = true, src = "https://embed.testimonial.to/carousel/all/outstandly-gamedev2?theme=light&autoplay=on&showmore=off&one-row=off&same-height=on", onClick = () => { } }) => {
	const testiRef = useRef(null);
	const buttonRef = useRef(null);
	const options = {
		animationData: heartJson,
		loop: true,
		autoplay: false
	}
	const { View, play, stop } = useLottie(options);
	useEffect(() => {
		if (hover === true) {
			buttonRef.current.onmouseleave = (event) => {
				stop();
			}
			buttonRef.current.onmouseenter = (event) => {
				play();
			}
		}
		else {
			play();
		}
	}, []);

	return (
		<div id="testimonials" className={style.testimonials}>
			<div className={style.testimonialsWrap}>
				<div className={`${style.animation} ${style.entry}`}>
					<Animation className={style.entryThreshold} json={entryJson} loop={true} />
				</div>
				<IframeResizer
					className={style.testiFrame}
					forwardRef={testiRef}
					heightCalculationMethod="lowestElement"
					inPageLinks
					src={src}
				/>
				<div className={`${style.animation} ${style.exit}`}>
					<Animation className={style.exitThreshold} json={exitJson} loop={true} />
				</div>
			</div>
			{
				button === true && (
					<button ref={buttonRef} type="button" className={style.wallButton} onClick={onClick}>
						<div className={style.heartAnimation}>{View}</div>
						<span>Wall of Love</span>
					</button>
				)
			}
		</div>
	)
}

export default Testimonials;