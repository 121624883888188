import React, { useEffect, useRef } from 'react';
import { useLottie } from "lottie-react";
import style from "./style.module.css";

const Animation = ({ json, loop = false, speed = 1, className = style.threshold, onFrameReach = false }) => {
	const lottieRef = useRef(null);
	const options = {
		animationData: json,
		loop: loop,
		onEnterFrame: (frame) => {
			if (onFrameReach !== false && frame.currentTime >= 60) {
				onFrameReach();
			}
		}
	}
	const { View, play, stop, pause, setSpeed } = useLottie(options);

	useEffect(() => {
		setSpeed(speed);

		let handlePlay = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					play();
				}
				else {
					pause();
				}
			});
		};

		let observer = new IntersectionObserver(handlePlay, { rootMargin: "0px" });
		observer.observe(lottieRef.current);
	}, []);

	return (
		<>
			<div className={className} ref={lottieRef}></div>
			{View}
		</>
	)
}

export default Animation;