import React, { useRef, useState, useEffect } from 'react';
import { hologramBottomJson, hologramTopJson } from '../../assets';
import Eyeball from '../../components/Eyeball';
import Animation from '../../components/Animation';
import style from "../style.module.css";

const Banner = ({ onClick }) => {
	/*useEffect(() => {
		var container = document.getElementById("banner"),
			inner = document.getElementById("bg"),
			logo = document.getElementById("logo"),
			eyeball = document.getElementById("eyeball_button_container"),
			character = document.getElementById("character");
		if (container) {
			// Mouse
			var mouse = {
				_x: 0,
				_y: 0,
				x: 0,
				y: 0,
				updatePosition: function (event) {
					var e = event || window.event;
					this.x = e.clientX - this._x;
					this.y = (e.clientY - this._y) * -2;
				},
				setOrigin: function (e) {
					this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
					this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
				},
				show: function () {
					return "(" + this.x + ", " + this.y + ")";
				}
			};

			mouse.setOrigin(container);
			var counter = 0;
			var refreshRate = 1;
			var isTimeToUpdate = function () {
				return counter++ % refreshRate === 0;
			};

			//----------------------------------------------------

			var onMouseEnterHandler = function (event) {
				update(event);
			};

			var onMouseLeaveHandler = function () {
				//inner.style = "";
			};

			var onMouseMoveHandler = function (event) {
				if (isTimeToUpdate()) {
					update(event);
				}
			};

			//----------------------------------------------------

			var update = function (event) {
				mouse.updatePosition(event);
				updateTransformStyle(
					(mouse.y / inner.offsetHeight / 2).toFixed(2),
					(mouse.x / inner.offsetWidth / 2).toFixed(2),
					mouse.x
				);
			};

			var updateTransformStyle = function (x, y, cx) {
				let style = "translate3d(" + (x * -5) + "px, " + y + "px, 0)";
				inner.style.transform = "rotateX(" + x + "deg) rotateY(" + y + "deg)";;
				logo.style.transform = style;
				eyeball.style.transform = style;
				if(cx > 20) {
					character.style.left = "20px";
				}
				else if(cx < -20) {
					character.style.left = "-20px";
				}
				else {
					character.style.left = "0px";
				}
			};

			//--------------------------------------------------------

			container.onmousemove = onMouseMoveHandler;
			container.onmouseleave = onMouseLeaveHandler;
			container.onmouseenter = onMouseEnterHandler;
		}
	}, []);*/

	const ref = useRef(null);
	const [height, setHeight] = useState(0);
	useEffect(() => {
		setHeight(ref.current.scrollHeight);
	}, []);
	return (
		<div id="bg" className={style.outstandly}>
			<div className={`${style.animation} ${style.entry}`}>
				<Animation className={style.entryThreshold} json={hologramTopJson} loop={true} />
			</div>
			<div ref={ref} className={style.outstandlyBg} style={{ height }}>
				<div className={style.outstandlyPadding}>
					<h1 id="logo" className={style.logo}>OUTSTANDLY</h1>
					<h2 id="caption">Your very own game art team.</h2>
					<p id="text" className={style.text}>
						<span>Badass concepts, 2D,3D & VR worlds, animations and UX/UI one-stop s̶h̶o̶p̶ metaverse.</span>
						<br /><span className={style.whatever}>(whatever that means)</span>
					</p>
					<button id="eyeball_button_container" type="button" className={`${style.eyeball}`} onClick={onClick}>
						<span>EYEBALL OUR SHOWREEL</span>
						<div className={style.eyeballAnim}>
							<Eyeball id="eyeball_button" />
						</div>
					</button>
				</div>
			</div>
			<div className={`${style.animation} ${style.exit}`}>
				<Animation className={style.exitThreshold} json={hologramBottomJson} loop={true} />
			</div>
		</div>
	)
}

export default Banner;