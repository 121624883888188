import React from 'react';
import CssAnimation from './CssAnimation';
import style from "../style.module.css";
import back from "../../assets/animation/logo/back.png";
import middle from "../../assets/animation/logo/middle.png";
import front from "../../assets/animation/logo/front.png";
import iback from "../../assets/animation/logo_invert/back.png";
import imiddle from "../../assets/animation/logo_invert/middle.png";
import ifront from "../../assets/animation/logo_invert/front.png";

const Logo = () => {
    return (
        <CssAnimation className={style.mirrorWrap}>
            <div className={style.mirror}>
                <div className={style.sizer}></div>
                <img src={back} alt="Logo Back" className={style.structureImg} />
                <img src={middle} alt="Logo Middle" className={`${style.structureImg} ${style.animatedLayer}`} />
                <img src={front} alt="Logo Front" className={`${style.structureImg} ${style.mirrorImg} ${style.animatedLayer}`} />
            </div>
            <div className={`${style.mirror} ${style.mirrorFlip}`}>
                <div className={style.sizer}></div>
                <img src={iback} alt="Logo Back" className={style.structureImg} />
                <img src={imiddle} alt="Logo Middle" className={`${style.structureImg} ${style.animatedLayer}`} />
                <img src={ifront} alt="Logo Front" className={`${style.structureImg} ${style.animatedLayer}`} />
            </div>
        </CssAnimation>
    )
}

export default Logo;