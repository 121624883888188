import React from 'react';
import { playJson } from "../../assets/index";
import Animation from '../../components/Animation';
import style from "../style.module.css";

const Play = () => {
    return (
        <div className={style.play}>
            <div className={`${style.animation} ${style.playJson}`}>
                <Animation className={style.playJsonThreshold} json={playJson} loop={true} />
            </div>
        </div>
    )
}

export default Play;