import React, { useRef, useEffect } from 'react';
import Play from "../../pages/Animation/Play";
import Eyeball from '../Eyeball';
import style from './style.module.css';

const Video = ({ id, mp4, webm, title = false, html = false, onClick = () => { } }) => {
	const videoRef = useRef(null);
	useEffect(() => {
		let options = {
			rootMargin: "0px",
			threshold: [0.25, 0.75]
		};

		let handlePlay = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					videoRef.current.play();
				}
				else {
					videoRef.current.pause();
				}
			});
		};

		let observer = new IntersectionObserver(handlePlay, options);
		observer.observe(videoRef.current);
	}, []);

	return (
		<>
			<video ref={videoRef} className={style.video} loop muted playsInline preload="true">
				<source src={mp4}></source>
				<source src={webm}></source>
			</video>
			{
				title !== false && (
					<div className={style.videoTitle}>{title}</div>
				)
			}
			{
				html !== false && (
					<div className={`${style.videoTitle} ${style.videoTitleSmall}`} dangerouslySetInnerHTML={{ __html: html }}></div>
				)
			}
			<button id={`${id}_container`} className={style.videoTrigger} type="button" onClick={onClick}>
				<div className={style.play}>
					<Play />
					<div className={style.eyeball}>
						<Eyeball id={id} red={true} />
					</div>
				</div>
			</button>
		</>
	)
}

export default Video;