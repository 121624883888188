import React from 'react';
import CssAnimation from './CssAnimation';
import style from "../style.module.css";
import bridge_top from "../../assets/animation/bridge/top.png";
import bridge_bottom from "../../assets/animation/bridge/bottom.png";
import bridge_people from "../../assets/animation/bridge/people.png";
import bridge_people_2 from "../../assets/animation/bridge/people_2.png";
import bridge_people_3 from "../../assets/animation/bridge/people_3.png";

const Bridge = () => {
    return (
        <CssAnimation className={style.bridge}>
            <div className={style.peopleWrap}>
                <div className={`${style.people} ${style.animatedLayer}`}>
                    {
                        [1, 2, 3, 4, 5, 6, 7].map((i) => (
                            <React.Fragment key={`bridge_${i}`}>
                                <img src={bridge_people} alt="People" className={style.peopleImg} />
                                <img src={bridge_people_2} alt="People" className={style.peopleImg} />
                                <img src={bridge_people_3} alt="People" className={style.peopleImg} />
                                <img src={bridge_people_2} alt="People" className={style.peopleImg} />
                            </React.Fragment>
                        ))
                    }
                </div>
            </div>
            <div className={`${style.structure} ${style.structureTop}`}>
                <div className={style.sizer}></div>
                <img src={bridge_top} alt="Bridge" className={style.structureImg} />
            </div>
            <div className={`${style.structure} ${style.structureBottom}`}>
                <div className={style.sizer}></div>
                <img src={bridge_bottom} alt="Bridge" className={style.structureImg} />
            </div>
        </CssAnimation>
    )
}

export default Bridge;