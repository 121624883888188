import React from 'react';
import CssAnimation from './CssAnimation';
import style from "../style.module.css";
import blue from "../../assets/animation/ship/blue.png";
import green from "../../assets/animation/ship/green.png";
import purple from "../../assets/animation/ship/purple.png";
import pink from "../../assets/animation/ship/pink.png";
import tower from "../../assets/animation/ship/tower.png";

const Ship = () => {
    return (
        <CssAnimation className={style.ship}>
            <div className={style.shipGreen}>
                <img src={green} alt="Green Ship" className={style.animatedLayer} />
            </div>
            <div className={style.shipBlue}>
                <img src={blue} alt="Blue Ship" className={style.animatedLayer} />
            </div>
            <div className={style.shipPurple}>
                <img src={purple} alt="Purple Ship" className={style.animatedLayer} />
            </div>
            <div className={style.shipPink}>
                <img src={pink} alt="Pink Ship" className={style.animatedLayer} />
            </div>
            <div className={style.shipTower}>
                <img src={tower} alt="Tower" className={style.animatedLayer} />
            </div>
        </CssAnimation>
    )
}

export default Ship;