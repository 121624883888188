import React, { useRef, useEffect } from "react";

export default function MyLandbot({ url, className, move = true }) {
    const containerRef = useRef(null);

    useEffect(() => {
        if (url) {
            new window.Landbot.Container({
                container: containerRef.current,
                configUrl: url
            });

            if (move === true) {
                var container = document.getElementById("landbotholder"),
                    inner = document.getElementById("landbot"),
                    phone = document.getElementById("phone");
                if (container) {
                    // Mouse
                    var mouse = {
                        _x: 0,
                        _y: 0,
                        x: 0,
                        y: 0,
                        updatePosition: function (event) {
                            var e = event || window.event;
                            this.x = e.clientX - this._x;
                            this.y = (e.clientY - this._y) * -2;
                        },
                        setOrigin: function (e) {
                            this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
                            this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
                        },
                        show: function () {
                            return "(" + this.x + ", " + this.y + ")";
                        }
                    };

                    mouse.setOrigin(container);
                    var counter = 0;
                    var refreshRate = 1;
                    var isTimeToUpdate = function () {
                        return counter++ % refreshRate === 0;
                    };

                    //----------------------------------------------------

                    var onMouseEnterHandler = function (event) {
                        update(event);
                    };

                    var onMouseMoveHandler = function (event) {
                        if (isTimeToUpdate()) {
                            update(event);
                        }
                    };

                    var onBotMouseEnterHandler = function (event) {
                        phone.style.transform = "translateX(-70px)";
                    };

                    var onBotMouseLeaveHandler = function () {
                        phone.style.transform = "translateX(0)";
                    };

                    //----------------------------------------------------

                    var update = function (event) {
                        mouse.updatePosition(event);
                        updateTransformStyle(
                            (mouse.y / inner.offsetHeight / 2).toFixed(2),
                            (mouse.x / inner.offsetWidth / 2).toFixed(2)
                        );
                    };

                    var updateTransformStyle = function (x, y) {
                        inner.style.transform = "rotateX(0deg) rotateY(" + y + "deg)";
                    };

                    //--------------------------------------------------------

                    container.onmousemove = onMouseMoveHandler;
                    container.onmouseenter = onMouseEnterHandler;
                    containerRef.current.onmouseenter = onBotMouseEnterHandler;
                    containerRef.current.onmouseleave = onBotMouseLeaveHandler;
                }
            }
        }
    }, []);

    return <div className={className} ref={containerRef} />;
}