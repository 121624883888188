import momentTimezone from 'moment-timezone';
import moment from 'moment';

export const convertDateToUnix = (d) => {
    return Math.floor(moment.unix(moment(d).valueOf() / 1000000));
}
export const formatDotNetDate = (d, format, timezone) => {
    if (!timezone) {
        timezone = "Europe/London";
    }
    if (!d) {
        return "";
    }
    if (!format) {
        format = "DD MMMM, YYYY";
    }
    return momentTimezone.tz(convertDateToUnix(d) * 1000, timezone).format(format);
}