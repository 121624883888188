import React from 'react';
import CssAnimation from './CssAnimation';
import style from "../style.module.css";
import blue from "../../assets/animation/skate/blue.png";
import purple from "../../assets/animation/skate/purple.png";

const Skating = () => {
    return (
        <CssAnimation className={style.skating}>
            <div className={style.skatingBlue}>
                <img src={blue} alt="Blue Skater" className={style.animatedLayer} />
            </div>
            <div className={style.skatingPurple}>
                <img src={purple} alt="Purple Skater" className={style.animatedLayer} />
            </div>
        </CssAnimation>
    )
}

export default Skating;