import React from 'react';
import style from "../style.module.css";
import { drink1, drink3 } from "../../assets/index";
import CssAnimation from '../Animation/CssAnimation';
import body from "../../assets/animation/photo/body.png";
import head from "../../assets/animation/photo/head.png";
import hand from "../../assets/animation/photo/hand.png";

const Hand = () => {
	return (
		<CssAnimation className={style.party}>
			<div className={`${style.drink}`}>
				<img src={drink1} alt="Drinking" />
				<img src={drink3} alt="Hand" className={style.animatedLayer} />
			</div>
			<div id="phone" className={`${style.phone}`}>
				<div className={`${style.phoneFlash} ${style.animatedLayer}`}></div>
				<img src={hand} alt="Hand" className={`${style.phoneHand} ${style.animatedLayer}`} />
				<img src={body} alt="Body" className={style.phoneBody} />
				<img src={head} alt="Head" className={`${style.phoneHead} ${style.animatedLayer}`} />
			</div>
		</CssAnimation>
	)
}

export default Hand;