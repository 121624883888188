import React, { useEffect, useState, useRef } from 'react';
import style from "../style.module.css";

const Hologram = () => {
    const handRef = useRef(null);
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        let handlePlay = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setAnimate(true)
                }
                else {
                    setAnimate(false)
                }
            });
        };

        let observer = new IntersectionObserver(handlePlay, { rootMargin: "0px" });
        observer.observe(handRef.current);
    }, []);

    return (
        <div ref={handRef} className={`${style.hologramLight}${animate === true ? ' ' + style.hologramAnim : ''}`}></div>
    )
}

export default Hologram;