import React, { useState } from 'react';
import $ from 'jquery';

import Banner from '../Common/Banner';
import Hand from '../Common/Hand';
import Video from "../../components/Video";
import VideoCarousel from "../Desktop/Carousel";
import Wall from '../Desktop/Wall';
import Testimonials from "../Common/Testimonials";
import Bridge from '../Animation/Bridge';
import MyLandBot from "../../components/MyLandBot";
import Chatbot from '../Common/Chatbot';
import Menu from "./Menu";
import Character from '../Animation/CharacterJson';
import Footer from '../../components/Footer';

import {
	character,
	building1_mobile, building2_mobile, building3_mobile, building4_mobile, building5_mobile, building6_mobile,
	mp4, webm
} from "../../assets/index";
import style from "../style.module.css";

const onMenuClick = (id) => {
	$('html, body').animate({
		scrollTop: $('#' + id).offset().top - 90
	})
}

const Mobile = () => {
	const [section, setSection] = useState(false);
	const [video, setVideo] = useState(false);
	const [wall, setWall] = useState(false);
	return (
		<>
			<div className={`${style.page} ${style.mobile}`}>
				<Menu onClick={onMenuClick} setSection={(id) => setSection(id)} />
				<div className={style.banner}>
					<Banner onClick={() => onMenuClick("showreel")} />
					<div className={style.character}>
						<Character mobile={true} />
					</div>
				</div>
				<div className={style.building}>
					{
						[building1_mobile, building2_mobile, building3_mobile, building4_mobile, building5_mobile, building6_mobile].map((item, i) => {
							return (
								<div className={style.buildingItem} key={i}>
									<img src={item} alt={`Building ${i}`} className={style.holder} />
									<img src={item} alt={`Building ${i}`} className={style.object} />
								</div>
							)
						})
					}
					<Bridge />
					<div id="showreel" className={style.video1Wrap}>
						<Video title="SHOWREEL" mp4={mp4} webm={webm} />
						<a className={style.videoTrigger} onClick={() => setVideo(1)}>&nbsp;</a>
					</div>
					<Testimonials src="https://embed.testimonial.to/w/outstandly-gamedev2?animated=on&theme=dark&shadowColor=transparent" hover={false} onClick={() => setWall(true)} />
					<Hand />
				</div>
				<div id="quote" className={style.landbotHolder}>
					<div id="landbot" className={style.landbotPosition}>
						<MyLandBot url="https://storage.googleapis.com/landbot.pro/v3/H-1392242-6M15CJR7FYPTG0UH/index.json" className={style.landbot} />
					</div>
				</div>
			</div>
			{
				video !== false && (
					<VideoCarousel selected={video} onClose={() => setVideo(false)} />
				)
			}
			{
				wall === true && (
					<Wall onClose={() => setWall(false)} />
				)
			}
			{
				section === "about" && (
					<Chatbot onClose={() => setSection(false)} />
				)
			}
			<Footer onMenuClick={onMenuClick} />
		</>
	)
}

export default Mobile;