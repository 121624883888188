import React, { useEffect, useState } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import $ from 'jquery';

import Menu from './Menu';
import Video from "../../components/Video";
import Animation from "../../components/Animation";
import MyLandBot from "../../components/MyLandBot";
import Chatbot from '../Common/Chatbot';
import VideoCarousel from "./Carousel";
import Wall from './Wall';
import Character from '../Animation/CharacterJson';
import Hologram from './Hologram';
import Banner from '../Common/Banner';
import Testimonials from '../Common/Testimonials';
import Hand from '../Common/Hand';
import Bridge from '../Animation/Bridge';
import Train from '../Animation/Train';
import Tube from '../Animation/Tube';
import Logo from '../Animation/Logo';
import Dog from '../Animation/Dog';
import Ship from '../Animation/Ship';
import Walker from '../Animation/Walker';
import Skating from '../Animation/Skating';
import Footer from '../../components/Footer';

import style from "../style.module.css";
import {
	ship1, ship2, ship3, ship4, ship5,
	cloud1, cloud3, cloud31, cloud4, cloud5, cloud6, cloud7, cloud8, cloud9, cloud10, cloud11, cloud12, cloud13,
	building1, building2, building3, building4, building5, building6,
	mp4, webm,
	bikerJson,
	mp42,
	webm2,
	coffeeJson
} from "../../assets/index";

const getComputedTranslateY = (obj) => {
    if(!window.getComputedStyle) return;
    var style = getComputedStyle(obj),
        transform = style.transform || style.webkitTransform || style.mozTransform;
    var mat = transform.match(/^matrix3d\((.+)\)$/);
    if(mat) return parseFloat(mat[1].split(', ')[13]);
    mat = transform.match(/^matrix\((.+)\)$/);
    return mat ? parseFloat(mat[1].split(', ')[5]) : 0;
}

const onMenuClick = (id) => {
	let offset = - 90;
	if (id === "quote") {
		offset = 0;
	}
	$('html, body').animate({
		scrollTop: $('#' + id).offset().top + offset
	}, 'slow')
}

const Desktop = () => {
	const [section, setSection] = useState(false);
	const [bot, setBot] = useState(false);
	const [video, setVideo] = useState(false);
	const [wall, setWall] = useState(false);
	const cloudsList = [cloud1, cloud3, cloud31, cloud4, cloud5, cloud6, cloud7, cloud8, cloud9, cloud10, cloud11, cloud12, cloud13];
	const cloudsClass = [style.cloud1, style.cloud3, style.cloud31, style.cloud4, style.cloud5, style.cloud6, style.cloud7, style.cloud8, style.cloud9, style.cloud10, style.cloud11, style.cloud12, style.cloud13];

	//Parallax on banner
	useEffect(() => {
		function onScroll() {
			$('#banner').css('transform', 'translateY(' +  Math.min(0, $(window).scrollTop() * -.5) + 'px)');
			$(`.${style.building}`).css('margin-bottom', Math.ceil(getComputedTranslateY($(`.${style.building}`)[0])) + 'px');
		}
		window.addEventListener("scroll", onScroll);
		return () => {
			window.removeEventListener("scroll", onScroll);
		}
	})

	return (
		<>
			<div className={`${style.page} ${style.desktop}`}>
				<Menu onClick={onMenuClick} setSection={(id) => setSection(id)} />
				<ParallaxProvider>
					<div id="banner" className={style.banner}>
						{
							cloudsList.map((item, i) => (
								<div className={`${style.cloud} ${cloudsClass[i]}`} key={i}>
									<img src={item} alt={`Cloud ${i}`} />
								</div>
							))
						}
						<Parallax className={style.object1} speed={15}>
							<img src={ship1} alt="Ship 1" />
						</Parallax>
						<Parallax className={style.object2} speed={30}>
							<img src={ship2} alt="Ship 2" />
						</Parallax>
						<Parallax className={style.object3} speed={10}>
							<img src={ship3} alt="Ship 3" />
						</Parallax>
						<Parallax className={style.object4} speed={0}>
							<img src={ship4} alt="Ship 4" />
						</Parallax>
						<Parallax className={style.object5} speed={5}>
							<img src={ship5} alt="Ship 5" />
						</Parallax>
						<div className={style.outstandlyWrap}>
							<Banner onClick={() => onMenuClick("showreel")} />
						</div>
					</div>
					<Parallax className={style.character} speed={20}>
						<Character />
					</Parallax>
					<Parallax className={style.building} speed={30}>
						{
							[building1, building2, building3, building4, building5, building6].map((item, i) => {
								return (
									<div className={style.buildingItem} key={i}>
										<div className={style.sizerBuilding}></div>
										<div className={style.bsz} style={{ backgroundImage: `url(${item})` }}></div>
									</div>
								)
							})
						}
						<div id="showreel" className={style.video1Wrap}>
							<div className={style.video1Angle}>
								<Video id="video1" title="SHOWREEL" mp4={mp4} webm={webm} onClick={() => setVideo(1)} />
							</div>
						</div>
						<div className={style.video2Wrap}>
							<div className={style.video2Angle}>
								<Video id="video2" html='Testimonial<br>by Bump Studios' mp4={mp42} webm={webm2} onClick={() => setVideo(2)} />
							</div>
						</div>
						<Logo />
						<Train />
						<Tube />
						<Bridge />
						<Dog />
						<Ship />
						<div className={`${style.animation} ${style.coffee}`}>
							<Animation className={style.coffeeThreshold} json={coffeeJson} loop={true} />
						</div>
						<Walker />
						<Skating />
						<div id="quote" className={`${style.animation} ${style.biker}`}>
							<Animation className={style.bikerThreshold} json={bikerJson} loop={false} onFrameReach={() => setBot(true)} />
						</div>
						<Hand />
						{
							/*<div className={`${style.animation} ${style.cars}`}>
								<Animation className={style.carsThreshold} json={carsJson} />
							</div>
							<div className={`${style.animation} ${style.fancycars}`}>
								<Animation className={style.fancycarsThreshold} json={fancycarsJson} />
							</div>*/
						}
						{
							bot === true && (
								<div id="landbotholder" className={style.landbotHolder}>
									<Hologram />
									<div id="landbot" className={style.landbotPosition}>
										<MyLandBot url="https://storage.googleapis.com/landbot.pro/v3/H-1389171-UVRZ3WQLN27OQBBU/index.json" className={style.landbot} />
										<button type="button" onClick={() => setSection("chatbot")} className={style.expandChatBot}></button>
									</div>
								</div>
							)
						}
					</Parallax>
				</ParallaxProvider>
				<Testimonials src="https://embed.testimonial.to/w/outstandly-gamedev2?animated=on&theme=dark&shadowColor=transparent" onClick={() => setWall(true)} />
				{
					video !== false && (
						<VideoCarousel selected={video} onClose={() => setVideo(false)} />
					)
				}
				{
					wall === true && (
						<Wall onClose={() => setWall(false)} />
					)
				}
				{
					section === "about" && (
						<Chatbot onClose={() => setSection(false)} />
					)
				}
				{
					section === "chatbot" && (
						<Chatbot url="https://chats.landbot.io/v3/H-819398-RRIH2PLRDI9DDB9V/index.json" onClose={() => setSection(false)} />
					)
				}
			</div>
			<Footer onMenuClick={onMenuClick} />
		</>
	)
}

export default Desktop;