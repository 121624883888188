import React, { useState, useRef, useEffect } from 'react';
import style from "../style.module.css";

const CssAnimation = ({ className, children }) => {
    const ref = useRef(null);
    const [paused, setPaused] = useState(true);
    useEffect(() => {
        let obRef = null;
        let handlePlay = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setPaused(false)
                }
                else {
                    setPaused(true)
                }
            });
        };

        obRef = ref.current;
        let observer = new IntersectionObserver(handlePlay, { rootMargin: "0px" });
        observer.observe(ref.current);

        return () => {
            if(obRef) {
                observer.disconnect();
            }
        }
    }, []);
    return (
        <div ref={ref} className={`${style.cssAnimation} ${className}${paused === true ? ' ' + style.paused : ''}`}>
            {children}
        </div>
    );
}

export default CssAnimation;