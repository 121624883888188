import React from 'react';
import { walkerJson } from "../../assets/index";
import Animation from '../../components/Animation';
import style from "../style.module.css";
import CssAnimation from './CssAnimation';

const Walker = () => {
    return (
        <CssAnimation className={`${style.walker}`}>
            <div className={`${style.animation} ${style.walkerAnimation} ${style.animatedLayer}`}>
                <Animation className={style.walkerThreshold} json={walkerJson} loop={true} />
            </div>
        </CssAnimation>
    )
}

export default Walker;