import React from 'react';
import DialogBox from "../../../components/DialogBox";
import Video from './Video';

const Carousel = ({ selected = 1, onClose }) => {
    return (
        <DialogBox onClose={onClose}>
            <Video selected={selected} />
        </DialogBox>
    )
}

export default Carousel;