import React, { useRef } from 'react';
import DialogBox from "../../../components/DialogBox";
import IframeResizer from 'iframe-resizer-react';
import style from "../../style.module.css";

const Wall = ({ onClose }) => {
    const wallRef = useRef(null);
    return (
        <DialogBox onClose={onClose} padding={false}>
            <div className={style.wallIframe}>
                <IframeResizer
                    forwardRef={wallRef}
                    heightCalculationMethod="lowestElement"
                    inPageLinks
                    src="https://embed.testimonial.to/w/outstandly-gamedev2?theme=light&card=base&loadMore=on&initialCount=20"
                />
            </div>
        </DialogBox>
    )
}

export default Wall;