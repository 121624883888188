import React from 'react';
import CssAnimation from './CssAnimation';
import style from "../style.module.css";
import train_engine from "../../assets/animation/train/train.png";
import train_front from "../../assets/animation/train/front.png";
import train_back from "../../assets/animation/train/back.png";

const Train = () => {
    return (
        <CssAnimation className={style.train}>
            <div className={style.trainWrap}>
                <img src={train_back} alt="Train Back" className={style.structureImg} />
                <img src={train_engine} alt="Train" className={`${style.trainEngine} ${style.animatedLayer}`} />
                <img src={train_front} alt="Train Back" className={style.structureImg} />
            </div>
        </CssAnimation>
    )
}

export default Train;