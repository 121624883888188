import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import style from "../../style.module.css";
import { thumb1, thumb2 } from "../../../assets/index";

const videoUrls = [
    "//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Ffast.wistia.net%2Fembed%2Fiframe%2F3e51a6zqen&display_name=Wistia%2C+Inc.&url=https%3A%2F%2Foutstandly.wistia.com%2Fmedias%2F3e51a6zqen&image=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2Fe9b0b6e5b394e70da0b3e6927d6fe631.jpg%3Fimage_crop_resized%3D960x540&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=wistia",
    "//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Ffast.wistia.net%2Fembed%2Fiframe%2Fp7c3h9hzub&display_name=Wistia%2C+Inc.&url=https%3A%2F%2Foutstandly.wistia.com%2Fmedias%2Fp7c3h9hzub&image=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2F45da18f5ce5cb7c63b4bb2122fcc4e84.jpg%3Fimage_crop_resized%3D960x540&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=wistia"
];

const Video = ({ selected = 1 }) => {
    const [active, setActive] = useState(selected);
    const [width, setWidth] = React.useState($(`.${style.videoCarousel}`).height() * 100 / 56.25);
    let timer = 0;
    useEffect(() => {
        function handleResize() {
            timer = setTimeout(() => {
                setWidth($(`.${style.videoCarousel}`).height() * 100 / 56.25)
            }, 50);
        }
        window.addEventListener('resize', handleResize)
        handleResize();
        return _ => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timer);
        }
    })
    return (
        <div className={style.videoCarousel}>
            <div className={style.videoIframe} style={{ maxWidth: width }}>
                <div className={style.sizer}></div>
                <iframe src={videoUrls[active - 1]} scrolling="no" title="Wistia, Inc. embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>
            </div>
            <div className={style.videoList}>
                <button className={style.videoThumbnail} onClick={() => setActive(1)}>
                    <img src={thumb1} alt="Thumbnail 1" />
                    {active === 1 && <div className={style.videoActive}></div>}
                </button>
                <button className={style.videoThumbnail} onClick={() => setActive(2)}>
                    <img src={thumb2} alt="Thumbnail 1" />
                    {active === 2 && <div className={style.videoActive}></div>}
                </button>
            </div>
        </div>
    )
}

export default Video;