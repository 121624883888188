import React from 'react';
import CssAnimation from './CssAnimation';
import style from "../style.module.css";
import people from "../../assets/animation/tube/people.png";
import front from "../../assets/animation/tube/front.png";
import back from "../../assets/animation/tube/back.png";

const Tube = () => {
    return (
        <CssAnimation className={style.tube}>
            <div className={style.tubeWrap}>
                <img src={back} alt="Tube Back" className={`${style.structureImg} ${style.tubeImg}`} />
                <div className={`${style.tubePeople} ${style.animatedLayer}`}>
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                            <img src={people} alt="People" className={style.tubePeopleImage} key={`tube_${i}`} />
                        ))
                    }
                </div>
                <img src={front} alt="Tube Front" className={style.structureImg} />
            </div>
        </CssAnimation>
    )
}

export default Tube;