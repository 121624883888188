import React from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';

import Home from './pages/index';

const App = () => {
	return (
		<BrowserRouter basename={`${process.env.REACT_APP_URL_PREFIX}`}>
			<Routes>
				<Route path="/" element={<Home />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App;