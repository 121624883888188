import React, { useEffect, useState } from 'react';
import { get, slice } from 'lodash';

import api from '../../actions/api';
import { formatDotNetDate } from '../Utils';

import footerLogo from '../../assets/footer_logo.svg';
import twitterLogo from '../../assets/twitter.svg';
import driveLogo from '../../assets/drive.svg';
import envelopeLogo from '../../assets/envelope.svg';
import buildingLogo from '../../assets/building.svg';

import style from './style.module.css';

const Footer = ({ onMenuClick }) => {
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        api.get(`/content/posts/?key=${process.env.REACT_APP_BLOG_KEY}`).then(function (response) {
            if (get(response, 'data.posts') && get(response, 'data.posts', []).length) {
                setPosts(slice(get(response, 'data.posts'), 0, 3));
            }
        });
    }, []);
    return (
        <div className={`${style.footer}`}>
            <div className={`${style.c}`}>
                <div className={`${style.items}`}>
                    <div className={`${style.item} ${style.logo}`}>
                        <a onClick={() => onMenuClick("banner")}><img src={footerLogo} /></a>
                        <div className={`${style.socialIcons}`}>
                            <a className={`${style.sItems} ${style.commonLink}`} href="https://twitter.com/outstandly" target="_blank"><img src={twitterLogo} /></a>
                            <a className={`${style.sItems} ${style.commonLink}`} href="https://www.artstation.com/outstandly" target="_blank"><img src={driveLogo} /></a>
                        </div>
                    </div>
                    <div className={`${style.item} ${style.blog}`}>
                        <div className={`${style.menuTitle}`}>Latest Blog Posts</div>
                        <div className={`${style.blogItems}`}>
                            {posts && posts.length > 0 && posts.map((postItem, i) => {
                                return (
                                    <a className={`${style.blogItem} ${style.commonLink}`} href={get(postItem, 'url')} target="_blank" key={i}>
                                        <div>{get(postItem, 'title')}</div>
                                        <div className={`${style.publishedAt}`}>{get(postItem, 'published_at') && formatDotNetDate(get(postItem, 'published_at'))}</div>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                    <div className={`${style.item} ${style.privacyLinks}`}>
                        <div className={`${style.menuTitle}`}>If Bored</div>
                        <div className={`${style.menuItems}`}>
                            <div className={`${style.menuItem} ${style.commonLink}`}>
                                <a href="https://www.iubenda.com/privacy-policy/22664126/cookie-policy" className="iubenda-white no-brand iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
                            </div>
                            <div className={`${style.menuItem} ${style.commonLink}`}>
                                <a href="https://www.iubenda.com/privacy-policy/22664126" className="iubenda-white no-brand iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
                            </div>
                            <div className={`${style.menuItem} ${style.commonLink}`}>
                                <a href="https://www.iubenda.com/terms-and-conditions/22664126" className="iubenda-white no-brand iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe " title="Terms and Conditions ">Terms and Conditions</a>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.item} ${style.contactLinks}`}>
                        <div className={`${style.menuTitle}`}>Contact Us</div>
                        <div className={`${style.detail}`}>
                            <div className={`${style.email}`}>
                                <a className={`${style.commonLink}`} href="mailto:cellardoor@outstandly.com"><span><img src={envelopeLogo} /></span><span>cellardoor@outstandly.com</span></a>
                            </div>
                            <div className={`${style.address}`}>
                                <span><img src={buildingLogo} /></span><span>Your love letters are accepted at:<br />207 Regent Street, Floor 3,<br />London W1B 3HH</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${style.copyrightsBorder}`}></div>
                <div className={`${style.copyrights}`}>
                    <div className={`${style.companyNumber}`}>Company number: 14135954</div>
                    <div className={`${style.rights}`}>© Outstandly Ventures Ltd {(new Date().getFullYear())} | All Rights Reserved</div>
                </div>
            </div>
        </div>
    )
}

export default Footer;