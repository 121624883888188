import React, { useRef, useEffect } from 'react';
import { characterJson } from "../../assets/index";
import { useLottie } from "lottie-react";
import style from "../style.module.css";

const Character = ({ mobile = false }) => {
    const divRef = useRef(null);
    const options = {
        animationData: characterJson,
        loop: false,
        autoplay: false
    }
    const { View, play, stop, pause, setSpeed, goToAndStop, goToAndPlay } = useLottie(options);

    useEffect(() => {
        if (mobile === true) {
            divRef.current.onclick = (event) => {
                goToAndPlay(0);
            }
        }
        else {
            divRef.current.onmouseenter = (event) => {
                goToAndPlay(0);
            }
        }
    }, [mobile]);

    return (
        <a ref={divRef} className={style.characterWrap}>
            {View}
        </a>
    )
}

export default Character;