import React from 'react';
import Link from '../../components/Link';
import style from '../style.module.css';

const Desktop = ({ onClick = () => { }, setSection }) => {
	return (
		<div className={style.header}>
			<div className={style.headerInner}>
				<div className={style.spacer}>
					<Link className={`${style.nav} ${style.first}`} onClick={() => setSection("about")}>
						<span>About</span>
					</Link>
					<Link className={`${style.nav}`} onClick={() => onClick("testimonials")}>
						<span>Testimonials</span>
					</Link>
					<Link className={`${style.nav} ${style.logo}`} onClick={() => onClick("banner")}>
						<span>OUTSTANDLY</span>
					</Link>
					<Link to="https://blog.outstandly.com/" className={`${style.nav}`}>
						<span>Blog</span>
					</Link>
					<Link className={`${style.nav} ${style.last}`} onClick={() => onClick("quote")}>
						<span>Get Quoted</span>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Desktop;