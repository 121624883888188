import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Desktop from './Desktop';
import Mobile from './Mobile';

const Home = () => {
	const [device, setDevice] = useState($(window).width() > 900 ? "desktop" : "mobile");
	useEffect(() => {
		function handleResize() {
			setDevice($(window).width() > 900 ? "desktop" : "mobile");
		}
		window.addEventListener('resize', handleResize)
		return _ => {
			window.removeEventListener('resize', handleResize)
		}
	})

	if (device === "desktop") {
		return (
			<Desktop />
		)
	}

	return (
		<Mobile />
	)
}

export default Home;