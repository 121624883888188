import React from 'react';
import DialogBox from "../../components/DialogBox";
import MyLandBot from "../../components/MyLandBot";
import style from "../style.module.css";

const Chatbot = ({ url = "https://landbot.pro/v3/H-1333830-CO1FQA5TYYMB7IMW/index.json", onClose }) => {
    return (
        <DialogBox onClose={onClose} padding={false}>
            <MyLandBot url={url} className={style.landbot} move={false} />
        </DialogBox>
    )
}

export default Chatbot;