import React from 'react';
import ModalBase from './ModalBase';
import Link from '../Link';
import style from './style.module.css';

export default class DialogBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openClass: "",
            closeClass: ""
        }
        this.openTimer = 0;
        this.closeTimer = 0;
    }

    componentDidMount() {
        this.openTimer = setTimeout(() => {
            this.setState({
                openClass: " " + style.dialogOpen
            })
        }, 50)
    }

    componentWillUnmount() {
        clearTimeout(this.openTimer);
        clearTimeout(this.closeTimer);
    }

    onCloseHandler = (callPropsFunc) => {
        const { onClose = () => { } } = this.props;
        if (callPropsFunc === undefined) {
            callPropsFunc = true;
        }
        this.closeTimer = this.setState({
            closeClass: " " + style.dialogRemove
        }, () => {
            if (callPropsFunc) {
                this.openTimer = setTimeout(() => {
                    onClose();
                }, 1250);
            }
        });
    }

    render() {
        const { content = false, buttons = false, showClose = true, padding = true, children, modalClass = "" } = this.props;
        const { openClass, closeClass } = this.state;
        return (
            <ModalBase className={modalClass}>
                <div className={`${style.dialogBoxOverlay}${padding === false ? " " + style.naked : ""}${openClass}${closeClass}`}>
                    <div className={style.dialogBox}>
                        <div className={`${style.dialogPosition}`}>
                            <div className={style.dialogHeader}>
                                {
                                    showClose === true && (
                                        <Link className={style.dialogClose} onClick={this.onCloseHandler}>&#215;</Link>
                                    )
                                }
                            </div>
                            <div className={`${style.dialogContent}`}>
                                {content && content(this.onCloseHandler)}
                                {children}
                                {buttons && <div className={style.dialogButtons}>{buttons(this.onCloseHandler)}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBase>
        );
    }
}