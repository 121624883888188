import React, { useState } from 'react';
import Link from '../../components/Link';
import ModalBase from '../../components/DialogBox/ModalBase';
import DialogBox from '../../components/DialogBox';
import style from '../style.module.css';

const Menu = ({ onClick = () => { }, setSection }) => {
	const [menu, setMenu] = useState(false);
	return (
		<>
			<ModalBase className={style.mobileHeader}>
				<Link className={`${style.mobileLogo}`} to="/">
					<span>OUTSTANDLY</span>
				</Link>
				<button type="button" className={style.burgerMenu} onClick={() => setMenu(!menu)}>
					<div className={`${style.burgerIcon}${menu === true ? ' ' + style.open : ""}`}>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</button>
			</ModalBase>
			{
				menu === true && (
					<DialogBox modalClass={style.mobileMenuModal} onClose={() => setMenu(false)} padding={false} showClose={false}>
						<div className={style.mobileMenu}>
							<Link className={`${style.nav}`} onClick={() => {
								setMenu(false);
								onClick("showreel");
							}}>
								<span>Showreel</span>
							</Link>
							<Link className={`${style.nav}`} onClick={() => {
								setMenu(false);
								setSection("about");
							}}>
								<span>About</span>
							</Link>
							<Link className={`${style.nav}`} onClick={() => {
								setMenu(false);
								onClick("testimonials")
							}}>
								<span>Testimonials</span>
							</Link>
							<Link to="https://blog.outstandly.com/" className={`${style.nav}`}>
								<span>Blog</span>
							</Link>
							<Link className={`${style.nav} ${style.last}`} onClick={() => {
								setMenu(false);
								onClick("landbot")
							}}>
								<span>Get Quoted</span>
							</Link>
						</div>
					</DialogBox>
				)
			}
		</>
	)
}

export default Menu;