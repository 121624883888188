import React, { useEffect } from 'react';
import $ from 'jquery';
import style from "./style.module.css";
import ball from "../../assets/eye/eye_ball.svg";
import pupil from "../../assets/eye/eye_pupil.svg";
import pupil_red from "../../assets/eye/eye_pupil_red.svg";

const Eyeball = ({ id, red = false }) => {
    useEffect(() => {
        const container = document.getElementById(`${id}_container`);
        container.addEventListener("mousemove", (event) => {
            const eye = $(`#${id}`);
            var x = (eye.offset().left) + (eye.width() / 2);
            var y = (eye.offset().top) + (eye.height() / 2);
            var rad = Math.atan2(event.pageX - x, event.pageY - y);
            var rot = (rad * (180 / Math.PI) * -1) + 180;
            eye.css({
                'transform': 'rotate(' + rot + 'deg)'
            });
        });
    }, []);

    return (
        <div className={style.eyeball}>
            <div className={style.eyeballMove} id={id}>
                <img src={ball} alt="Eye Ball" />
                <img className={style.pupil} src={red === false ? pupil : pupil_red} alt="Eye Pupil" />
            </div>
        </div>
    )
}

export default Eyeball;